import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`A little more about myself:`}</p>
    </blockquote>
    <p>{`Floyd Noel is a software engineering leader who works with clients and
developers to ensure high quality outcomes, efficiency, and team cohesion. He
understands that the key to successful software projects is open
communication, a highly motivated team, and alignment between stakeholders and
developers. Floyd has worked with corporations, non-profits, government
agencies, start-ups, and individual clients to deliver a wide array of
software and hardware applications. Floyd has served worldwide in the US Marine
Corps infantry and holds a BA in Economics with Computer Science from Boise
State University. He enjoys spending time with his family, enjoying Florida's
endless summers in the pool, and playing board games and Smash Bros with the kids.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      