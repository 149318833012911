import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Products and Services`}</h2>
    <ProjectCard title='Screenings and Interviews' link='mailto:support@camasdigital.com' bg='linear-gradient(to right, #D4145A 0%, #FBB03B 100%)' mdxType="ProjectCard">
  I perform technical screenings and interviews for your business. Specializng
  in modern JavaScript, TypeScript, React, and React Native interviews.
    </ProjectCard>
    <ProjectCard title='Bongo' link='https://bongo.to' bg='linear-gradient(to right, #662D8C 0%, #ED1E79 100%)' mdxType="ProjectCard">
  Bongo.to is a music sharing service built for sharing music. Users can share
  songs, albums, and playlists across streaming platforms.
    </ProjectCard>
    <ProjectCard title='exceldate' link='https://github.com/floydnoel/exceldate' bg='linear-gradient(to right, #009245 0%, #FCEE21 100%)' mdxType="ProjectCard">
  <pre style={{
        display: 'inline'
      }}>exceldate</pre> is an npm library which
  allows you to convert spreadsheet-formatted dates into JavaScript Date
  objects.
    </ProjectCard>
    <ProjectCard title='Bespoke Software' link='mailto:support@camasdigital.com' bg='linear-gradient(to right, #D585FF 0%, #00FFEE 100%)' mdxType="ProjectCard">
  I enjoy building bespoke applications harnessing the power of React and modern
  ECMAScript.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      